import { Content } from '@pp/components/Content'
import { Layout } from '@pp/components/Layout'
import { SEO } from '@pp/components/SEO'
import { Flex } from '@prontopro/ui-toolkit'
import React from 'react'

const Page = () => (
  <Layout>
    <SEO title="404: Not found" />

    <Content>
      <Flex align="center" justify="center">
        <div>
          <img
            alt="404: Not found"
            src="https://media.giphy.com/media/eKv7g4mWJLYXK/giphy.gif"
            style={{ maxWidth: '100%' }}
          />
        </div>
        <h1>Dyno not found</h1>
        <p>You just hit an island where no dinosaurs exist... the sadness.</p>
      </Flex>
    </Content>
  </Layout>
)

export default Page
